/* styles.module.css */

.currencySelectorContainer {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.currencySelect {
  padding: 10px 30px 10px 10px;
  border-radius: 20px;
  background-color: transparent;
  appearance: none;
  /* Optional: Removes default select arrow on most browsers */
  border: 1px solid #ddd;
  color: #333;
  width: 100%;
}

.currencySelect:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Adjust dropdown positioning */
.currencySelect option {
  padding: 10px;
  margin-right: 5px;
}


.customSelect {
  color: #ffffff;
  /* Default text color */
  /* background-color: #83957e00 !important ; */
  background-color: transparent;
  /* Transparent dropdown background */

  /* Background color */
  border: 1px solid #ccc;
  /* Border styling */
  border-radius: 25px;
  /* Rounded corners */
  padding: 10px 30px 10px 10px;
  /* Padding */
  appearance: none;
  /* Remove default arrow styling for better control */
}

.customSelect option {
  background-color: #132a16;
  /* Semi-transparent background */
  color: #ffffff;
  /* Text color */
}

.customSelect option:hover {
  background-color: rgba(131, 149, 126, 0.5) !important;
  /* Lighter transparency on hover */
  color: #1a9120 !important;
  /* Hover text color */
}

.customSelect option:checked {
  /* background-color: #1a9120; */
  /* Highlighted option with transparency */
  /* color: #ffffff; */
  /* Text color for the selected option */
  /* font-weight: bold; */
}