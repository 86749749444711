.projectDetailPage {
  display: flex;
  gap: 20px;
  padding: 80px 0;
}

.imageGallery {
  flex: 2;
}

.mainImage img {
  width: 100%;
  height: 400px;
  border-radius: 1rem;
  object-fit: cover;
}

.thumbnails {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}
.numberButtonPrimary{
  color: white;
  border-color: #198754;
}
.btnPrimaryOne{
  /* background-color: aqua; */
  background: linear-gradient(rgb(118, 204, 129) 0%, rgb(51, 144, 80) 100%);
  color: white;
}
.plantTreesBtn{
  padding: 0.5rem 10rem;
}
.priceValue{
  font-weight: 700;
  margin-top: 0.5em;
}
.pricingContainer{
  border: 1px solid #198754;
  padding: 10px;
  border-radius: 10px;
}
.numberButtonPrimary:hover{
  background: linear-gradient(rgb(118, 204, 129) 0%, rgb(51, 144, 80) 100%);
  color: white;
}

.checkoutPara{
  color: black;
  font-weight: 700;
  cursor: pointer;
  margin-top: 1em;
}
.checkoutNameS{
  font-size: 26px;
}

/* 
.carouselItem {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.thumbnail {
  width: 100px;
  height: 8rem;
  cursor: pointer;
  border-radius: 5px;
  object-fit: cover;
}

.contentSection {
  flex: 3;
}

.contentSection h1 {
  padding-top: 0;
}

.donationOptions {
  margin-top: 20px;
}

.actionButtons {
  margin-top: 20px;
}

.adoptTreeButton,
.plantNowButton {
  padding: 10px;
  background-color: #1a9120;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.counter {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px !important;
  padding-left: 2.8rem;
}

.buyBtnstyle {
  padding-left: 2.2rem;
}

.counterButton {
  padding: 10px;
  background-color: #1a9120;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.counterValue {
  font-size: 1.2rem;
  color: white;

}

.priceSection {
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #28a745;
}

.adoptTreeButton::before,
.adoptTreeButton::after,
.plantNowButton::before,
.plantNowButton::after {
  content: none;
  background: none;
  z-index: auto;
}

.iconList {
  list-style-type: none;
  padding: 0;
  padding-top: 1rem;
}

.iconList li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
  color: #1A9120;
}

.thumbnail {
  cursor: pointer;
  transition: filter 0.3s ease;
}

.thumbnail.blur {
  filter: blur(1px);
}

.thumbnail.active {
  border: 2px solid #1A9120;
}

.buyNowButton {
  padding: 10px 20px;
  border: none;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.plantBtnMargin {
  margin-right: 2rem;
}

/* new Logic CSS */

.plantNowBox {
  /* background: rgba(255, 255, 255, 0.2); */
  border-radius: 16px;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  /* backdrop-filter: blur(5px); */
  /* -webkit-backdrop-filter: blur(5px); */
  /* border: 1px solid rgba(255, 255, 255, 0.3); */
  padding: 10px 0;
}
.pointPara{
  font-size: 1rem;
  color: #e7e6e6;
}


/* slider thumbnail */

.carouselContainer {
  margin-top: 20px;
}

.carouselSlide img {
  width: 100%;
  cursor: pointer;
}

.mainImage img {
  width: 100%;
}

.counterSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.counterSection button {
  margin: 0 10px;
}

.thumbnailWrapper{
  /* width: 75px;
  height: 80px;
  object-fit: contain; */
  display: flex !important;
  /* gap: 10px !important; */
  margin-top: 10px;
  justify-content: center !important;
}

.counterBg{
  background: #1a9120;
  padding: 0.7rem 0;
  border-radius: 0.5rem;
}
.buyBtnStyle{
  margin-left: 2rem;
    background: white;
    color: black;
    padding: 7px 13px;
}
.StripText{
  margin-right: 2rem;
  color: white;
  font-size: 1.5rem;
  }

@media (min-width: 768px) {
  .projectDetailPage {
    flex-direction: row;
  }

  .actionButtons {
    flex-direction: row;
    justify-content: flex-start;
  }

  .adoptTreeButton,
  .plantNowButton {
    width: auto;
    max-width: none;
  }
}


@media (max-width: 767px) {
  .projectDetailPage {
    flex-direction: column;
  }

  .priceSection {
    font-size: 1.2rem;
  }
}

@media (max-width: 426px) {

.cartContainer{
  padding-left: 1rem;
  padding-right: 1rem;

}
.StripText{
margin-right: 1rem;
color: white;
font-size: 0.9rem;
}
}
/* Carousel Navigation Arrows */
.ant-carousel .slick-prev, .ant-carousel .slick-next {
  width: 40px;  /* Increase size for better visibility */
  height: 40px; /* Increase size for better visibility */
  color: #fff;  /* Bright color for the arrow icon */
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-radius: 50%; /* Rounded buttons */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Ensure buttons are on top */
}

.ant-carousel .slick-prev:before, .ant-carousel .slick-next:before {
  font-size: 24px; /* Adjust font size for visibility */
  color: #fff; /* Color of the arrows */
}

/* Positioning */
.ant-carousel .slick-prev {
  left: 20px; /* Adjust left position */
}

.ant-carousel .slick-next {
  right: 20px; /* Adjust right position */
}

/* Hover Effects */
.ant-carousel .slick-prev:hover, .ant-carousel .slick-next:hover {
  background: rgba(0, 0, 0, 0.8); /* Darker background on hover */
}


.customArrowLeft,
.customArrowRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border: none;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 100;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customArrowLeft {
    left: -50px;
}

.customArrowRight {
    right: -50px;
}

/* Responsive adjustments */
@media (min-width: 768px) {
    .customArrowLeft {
      left: -20px;
    }
  
    .customArrowRight {
      right: -20px;
    }
  }
  
  @media (min-width: 1024px) {
    .customArrowLeft {
      left: -30px;
    }
  
    .customArrowRight {
      right: -30px;
    }
  }
  
  @media (min-width: 1440px) {
    .customArrowLeft {
      left: 10px; /* Original large-screen position */
    }
  
    .customArrowRight {
      right: 10px;
    }
  }

