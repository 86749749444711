.sidebar {
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .sidebar ul {
    padding-left: 0;
  }
  
  .sidebar h5 {
    font-size: 1.25rem;
    color: white;
  }
  
  .sidebar ul ul {
    padding-left: 1rem;
    list-style: none;
  }
  
  .sidebar ul ul li .link {
    color: white !important;
    text-decoration: none !important;
  }

  .sidebar ul ul li{
    padding-bottom: 10px;
    text-decoration: none;
  }
  
  .sidebar ul ul li .link:hover {
    text-decoration: underline;
  }
  
  .active {
    background-color: #ffffff;
    color: black !important;
    border-radius: 5px;
    padding: 5px;
  }

  .activityIcon{
    font-size: 1rem;
    padding-right: 10px;
  }
  

  /* styles.css */
.table-view {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.table-view th,
.table-view td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.vertical-view {
  display: block;
  width: 100%;
}

.vertical-view div {
  margin: 10px 0;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .vertical-view {
      display: none; /* Hide vertical view on larger screens */
  }
}

@media (max-width: 768px) {
  .table-view {
      display: none; /* Hide table view on smaller screens */
  }
}
