
/* .container {
    max-width: 800px;
  } */
  
  .card {
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    background: rgb(183 183 183 / 23%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgb(0 0 0 / 20%);
    backdrop-filter: blur(-1px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  .profile-photo {
    border: 2px solid #007bff;
  }
  
  .ml-3 {
    margin-left: 1rem;
  }
  .labelColor{
    color: white;
  }
  .profileContainer{
    padding:70px 0;
  }
  