/* .carouselBackground {
    position: relative;
}

.carouselBackgroundImg {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.carouselSlide {
    position: relative;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8); 
    padding: 20px;
    margin: 10px;
} */
/* .backgroundImage {
    position: absolute;
    z-index: -1; 
} */
.mobileParaText {
    display: none;
}


@media (max-width:426px) {
    .serviceContainer {
        margin-bottom: 50px;
    }

    .mobileParaText {
        display: block;
    }

    .desktopParaText {
        display: none;
    }

    .CounterSectionPadding {
        padding-top: 0;
        /* margin-bottom: 5rem; */
    }

    .desktopCounterImage {
        display: none;
    }

    .serviceArea {
        padding-bottom: 12px;
    }
}


@supports (-webkit-touch-callout: none) {
 /* iOS-specific adjustment */
 .serviceContainer {
  margin-bottom: 50px;
}

 
}

.brandSlider {
    /* display: flex; */
    align-items: center;
}

.brandItem {
    /* margin-right: 100px; */
    display: inline-block;
    padding: 10px 10px;
    margin: 0 10px
}

.brandItem img {
    width: 15rem;
    height: 7rem;
    border-radius: 4px;
}

.marquee {
    display: flex;
    align-items: center;
}

.currency-c
{
    margin:0;
    padding: 0;
    align-items: center;

}


.project-img {
    width: 100%; /* Make the container responsive */
    height: 0;
    padding-top: 56.25%; /* 16:9 aspect ratio. Adjust if needed */
    position: relative; /* Make the container relative for absolutely positioned image */
    background-color: #f0f0f0; /* Optional fallback background */
    overflow: hidden; /* Hide overflow for images that are too large */
  }
  
  .project-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the image covers the container, keeping its aspect ratio */
    object-position: center; /* Center the image within the container */
  }
  


  /* testimonial css  */

  
  .carouselButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgb(255, 255, 255);
    border: none;
    color: rgb(0, 0, 0);
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    z-index: 1;
  }
  
  .leftButton {
    left: 10px;
  }
  
  .rightButton {
    right: 10px;
  }
  
  .carouselButton:hover {
    background-color: rgb(255, 255, 255);
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .leftButton {
      left: -20px;
    }
  
    .rightButton {
      right: -20px;
    }
  }
  
  @media (min-width: 1024px) {
    .leftButton {
      left: -30px;
    }
  
    .rightButton {
      right: -30px;
    }
  }
  
  @media (min-width: 1440px) {
    .leftButton {
      left: -10px; /* Original large-screen position */
    }
  
    .rightButton {
      right: -10px;
    }
  }




  .brandSection {
    position: relative;
    padding: 50px 0;
    background-color: #f9f9f9;
    overflow: hidden;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}

.scrollWrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.scrollContent {
    display: flex;
    align-items: center;
    animation: scroll 12s linear infinite;
}

.scrollContent img {
    max-width: 200px;
    margin: 0 10px;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}




/* ///////// */

.customArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.leftArrow {
  left: 10px;
}

.rightArrow {
  right: 10px;
}

.customArrow img {
  width: 100%;
  height: auto;
}


.testimonial-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 15px;
  max-width: 400px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.testimonial-card-content {
  text-align: center;
  width: 100%;
}

.testimonial-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-image-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #28a745;
  margin-bottom: 10px;
}

.testimonial-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-info {
  margin-bottom: 15px;
}

.testimonial-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 5px 0;
}

.testimonial-position {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

.testimonial-rating {
  color: #f4c150; /* Gold color for the stars */
  font-size: 14px;
}

.testimonial-text {
  font-size: 14px;
  color: #555;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .testimonial-card {
    max-width: 350px;
  }
}

@media (min-width: 1024px) {
  .testimonial-card {
    max-width: 300px;
  }
}

.homeBlogSection
{
  padding-bottom: 50px;
}

/* blog css */


.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgb(255, 255, 255);
  border: none;
  color: rgb(0, 0, 0);
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

.arrow.prev {
  left: -10px; /* Adjust as needed */
}

.arrow.next {
  right: -10px; /* Adjust as needed */
}

/* .arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
} */
