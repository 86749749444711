.card {
    position: relative;
    width: 300px;
    height: 400px;
    margin: 20px;
    overflow: hidden;
    border-top-right-radius: 5rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.5s, box-shadow 0.5s;
    /* background-color: #fff; */
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
  
  .cardImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 5rem;
    transition: transform 0.5s ease;
  }
  
  .card:hover .cardImg {
    transform: scale(1.05);
  }

  /* Wave effect */
/* .card::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.8s ease;
    z-index: 1;
  }
  
  .card:hover::before {
    transform: translate(-50%, -50%) scale(1);
  } */
  
  .cardContentContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    /* border-bottom-left-radius: 9rem; */
    padding: 10px 20px;
  }
  
  .cardContent {
    color: #fff;
    flex: 1;
  }
  
  .cardTitle {
    /* font-size: 1.2rem; */
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0;
  }
  
  .cardLocation {
    font-size: 0.9rem;
    margin: 0;
  }
  
  .arrowIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    /* background: #ff5722; */
    background: #44934a;
    border-radius: 50%;
    margin-left: 10px;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .arrowIconContainer:hover {
    background: #44934aad;
    transform: translateX(5px);
  }
  
  .arrowIcon {
    color: #fff;
    font-size: 1.2rem;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .card {
    animation: fadeInUp 0.5s ease-out;
  }
  
  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  