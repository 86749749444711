/* Container */
/* .container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
} */

/* Title */
.title {
    text-align: center;
    margin-bottom: 10px !important;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    padding-top: 0;
}

.containerFluidCrisis {
    position: relative;
    z-index: 3;
    /* Ensure content is above the darkened background */
    color: white;
    /* Ensure text is visible over dark background */
}

.crisistitleStyle,
.textStyle p {
    color: #fff;
    /* Ensure the text color is white to stand out against the darker background */
}

.Gallerytitle {
    text-align: center;
    margin-bottom: 30px;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
}

/* Buttons in a row */
.buttonGroup {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

/* Card Grid */
.cardGrid {
    display: flex;
    flex-wrap: wrap;
    /* gap: 20px; */
    justify-content: center;
}

/* Card */
.card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
    padding: 10px;
    text-align: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

/* Card Image */
.cardImg {
    width: 100%;
    height: 250px;
    /* height: auto; */
    border-radius: 5px;
}

/* Card Title */
.cardTitle {
    margin: 5px 0 10px;
    color: white;
    text-align: left;
    font-weight: 400;
}

/* Card Location and Price */
.cardLocation,
.cardPrice {
    margin: 5px 0;
    color: white;
    text-align: left;
}

.cardLocation {
    font-weight: 400;

}

.cardPrice {
    font-weight: 700;
    /* font-size: 20px; */
}

.popupCardPrice {
    font-size: 20px;
}

/* Counter */
/* .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 15px 0;
} */
.counter {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.counterBtn {
    /* background-color: #ddd; */
    border: none;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    color: white !important;
}

.counterValue {
    font-size: 16px;
    color: white;
}

.counterStyle {
    /* width: 50%; */
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    align-items: center;

}

/* Adopt Button */
.adoptBtn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
}


/* About.module.css */

.iconContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.iconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* border: 1px solid #234226;
    border-radius: 10px;
    padding: 2rem; */
}

.icon {
    font-size: 20px;
    color: #4CAF50;
}

.abouticon {
    font-size: 20px;
    color: #4CAF50;
    margin-right: 7px;
}

.count {
    font-size: 36px;
    margin-top: 10px;
    color: white;
    font-weight: 700;
}

.name {
    color: white;
}

.buyNowButton {
    /* margin-top: 20px; */
    background-color: #4CAF50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.buyNowButton:hover {
    background-color: #75d078;
}

.iconWrapper img {
    border-radius: 50%;
    background-color: #f0f0f0;
    padding: 10px;
    width: 100px;
    height: 100px;
}

.aboutStyle {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px 0px;
}

.projectImg img {
    height: 350px;
    width: 100%;
    object-fit: cover;
}


/* climate crisis styles */

.carouselSlide {
    padding: 20px;
    /* background-color: #f5f5f5; */
}



.contentContainer {
    max-width: 1200px;
    margin: auto;
}

.textContent {
    padding: 20px;
    text-align: left;
}

.textStyle {
    /* border-radius: 1rem;
    padding: 29px 30px;
    width: 48%;
    margin: auto;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3); */
    padding: 60px 0;
    position: relative;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: auto;
    /* margin-top: 3rem;
    margin-bottom: 3rem; */
}

.textContent h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.textContent p {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
}

.imageCarousel {
    padding: 20px;
}

.carouselImage {
    width: 100%;
    height: auto;
    /* border-radius: 2rem; */
    border-radius: 10px 40px 10px 40px;
}


/* about style css */

.partneraboutArea {
    padding: 60px 0;
    position: relative;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    /* Transparent white background */
    border-radius: 10px;
    /* Rounded corners */
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* Semi-transparent border */
    backdrop-filter: blur(10px);
    /* Frosted glass effect */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Slight shadow for depth */
    margin-top: 3rem;
    margin-bottom: 3rem;
}


.partnercontentContainer {
    padding: 20px;
    text-align: left;
}

.partnertitle {
    font-size: 30px;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 800;
}

.partnerdescription {
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    margin-bottom: 20px;
}

.partnerlistPoints {
    list-style: none;
    padding: 0;
}

.partnerlistPoints li {
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #fff;
}

.partnercheckIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.aboutRowContainer {
    position: relative;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* .partnercarouselContainer {
    padding: 20px;
} */

.partnercarouselContainer {
    perspective: 1000px;
}

.partnercarouselImage {
    width: 100%;
    height: auto;
    border-radius: 2rem;
}

.crisistitleStyle {
    /* font-weight: 800; */
    font-size: 2.8rem;
}

.treeLeaf {
    position: absolute;
    top: 39rem;
    right: 40%;
    z-index: -1;
    animation: sway 3s infinite ease-in-out;
    transform-origin: bottom;
}



@keyframes sway {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(2deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-2deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@media (max-width:768px) {
    .containerFluid {
        padding-top: 0px;
    }

    .containerFluidCrisis {
        padding-top: 0;
    }
}

@media (max-width:600px) {
    .textStyle {
        width: 100%;
        margin: 20px auto;
        box-shadow: #87e08cb3 0px 2px 8px 0px;
    }

    .treeLeaf {
        display: none;
    }

    .Gallerytitle {
        margin-bottom: 65px;
    }

    .crisistitleStyle {
        font-size: 2.3rem;
        line-height: 1;
        text-align: center;
    }

    .crisistextStyless {
        text-align: center;
    }

    .title {
        text-align: center;
        margin-bottom: 0px !important;
        font-size: 32px;
        line-height: 60px;
        padding-top: 23px;
    }

    .containerFluid {
        padding-bottom: 0 !important;
    }
}







/* 
-------------------------------------
why pataner with us css
------------------------------------- */

.customArrowLeft,
.customArrowRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border: none;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 100;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customArrowLeft {
    left: -50px;
}

.customArrowRight {
    right: -50px;
}

/* Responsive adjustments */
@media (min-width: 768px) {
    .customArrowLeft {
      left: -20px;
    }
  
    .customArrowRight {
      right: -20px;
    }
  }
  
  @media (min-width: 1024px) {
    .customArrowLeft {
      left: -30px;
    }
  
    .customArrowRight {
      right: -30px;
    }
  }
  
  @media (min-width: 1440px) {
    .customArrowLeft {
      left: 10px; /* Original large-screen position */
    }
  
    .customArrowRight {
      right: 10px;
    }
  }


/* --------------------------------------------
climatecrisis css
-------------------------------------------- */

.partneraboutClimateCrisis{
    width: 100%;
    padding: 60px 0;
    border-radius: 10px;
   
}


