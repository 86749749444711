.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popupContainer {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
}

.popupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.popupHeader h1 {
    margin: 0;
    font-size: 2rem;
    color: #000000;
}

.closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333333;
}

.popupForm {
    display: flex;
    flex-direction: column;
}

.formLabel {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #555555;
}

.formInput {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 1rem;
}

.errorMessage {
    color: red;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.submitButton {
    background-color: green;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background-color: #09aa09;
}

.infoText {
    margin-top: 15px;
    font-size: 0.9rem;
    color: #666666;
}
