.reset-password-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 20px;
}

.form-wrapper {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-wrapper h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.form-wrapper .form-label {
    font-weight: 500;
}

.form-wrapper .btn-primary {
    background-color: green;
    border-color:green;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 20px;
}
.resetPasswordPage{
    background-color: #ffffff71;
    border-radius: 10px;
    margin: 100px 0px;
   
}
.headingText{
    font-size: 2rem;
    font-weight: bold;
    color: #000000; 
}

.customButton{
    background-color: green;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 15px;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
}
.customButton:hover{
    background-color: #09aa04;
}
.customLabel{
    font-weight: 600;
    font-size: 1rem;
    color: #000000;
}
@media (max-width: 768px) {
    .form-wrapper {
        padding: 20px;
    }
}

@media (max-width: 576px) {
    .form-wrapper h2 {
        font-size: 1.25rem;
    }
}
